import React from 'react';

import RouteNames from '../../routes/names';

const About = (props) => {

  const aboutUrl = RouteNames.About.replace(':lang', props.lang);

    return (
      <section id="who" className="who section">

        <div className="container text-center">

          <h2 className="title text-center">Who we are</h2>

          <p className="intro text-center">
            We are a team of IT professionals based in Montreal.
            Over the years, we have helped software companies build great solutions.
          </p>

          <div className="row benefits text-center">

            <div className="item col-lg-4 col-md-6 col-12">
              <div className="item-inner">
                <div className="fs1" aria-hidden="true" data-icon="&#xe009;"></div>
                <h3 className="sub-title">Custom Solutions</h3>
                <div className="desc">
                  <p>We create cross-platform standalone and client-server business applications ensuring stable functioning, high performance and usability.</p>
                </div>
              </div>
            </div>

            <div className="item col-lg-4 col-md-6 col-12">
              <div className="item-inner">
                <div className="fs1" aria-hidden="true" data-icon="&#xe011;"></div>
                <h3 className="sub-title">Review &amp; Refactoring</h3>
                <div className="desc">
                  <p>We review your source code and propose a refactoring plan to improve the performance, stability and maintainability of your product.</p>
                </div>
              </div>
            </div>

            <div className="item col-lg-4 col-md-6 col-12">
              <div className="item-inner">
                <div className="fs1" aria-hidden="true" data-icon="&#xe007;"></div>
                <h3 className="sub-title">Training &amp; Courses</h3>
                <div className="desc">
                  <p>We offer a set of courses to help your team gain advanced knowledge of object oriented programming and write clean & quality code.</p>
                </div>
              </div>
            </div>

          </div>

          <a className="btn btn-cta btn-cta-secondary" href={aboutUrl}>Learn More</a>

        </div>

      </section>
    );
  }

export default About;
