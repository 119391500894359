import frMessages from '../locales/fr';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/fr';

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';

const frLang = {
    messages: {
        ...frMessages
    },
    locale: 'fr'
};

export default frLang;
