import firebase from 'firebase/app';

import 'firebase/functions';

firebase.initializeApp({
    apiKey: 'AIzaSyDbcvOZvt7p1ypwTFgIeDgJXWMfQ4Q5NuE',
    authDomain: 'kenz-8a80d.firebaseapp.com',
    projectId: 'kenz-8a80d'
  });
  
export const addMessage = firebase.functions().httpsCallable('addMessage');
export const subscribeEmail = firebase.functions().httpsCallable('subscribeEmail');