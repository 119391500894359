import { companyName, phoneNumber, emailAddress } from './constant';

const data = ({   
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "url": "https://www.kenzsoftware.com",
    "image": "https://www.kenzsoftware.com/logo.png",
    "name": companyName,
    "telephone": phoneNumber,
    "email":"mailto:" + emailAddress,
    "openingHoursSpecification": [
    {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday"
      ],
      "opens": "09:00",
      "closes": "17:00"
    }
  ]
});

export default data;