import React from 'react';

import RouteNames from '../names';

const Services = (props) => {

    const contactUrl = RouteNames.Contact.replace(':lang', props.lang);

    return (
      <section id="services" className="services section pt-0">

        <div className="container text-center">

          <h2 className="title text-center">Our Services</h2>

          <p className="intro">
            We do everything we can to offer an outstanding customer experience - helping to change business life for the better.
          </p>

          <div className="service-items row">

            <div className="item col-xl-4 col-md-6 col-12">
              <div className="item-inner">
                <div className="header-box">
                  <span className="fs1" aria-hidden="true" data-icon="&#xe009;"></span>
                </div>
                <div className="desc">
                  <h3 className="sub-title">Custom Solutions</h3>
                  <p>We create cross-platform standalone and client-server business applications ensuring stable functioning, high performance and usability.</p>
                </div>
              </div>
            </div>

            <div className="item col-xl-4 col-md-6 col-12">
              <div className="item-inner">
                <div className="header-box">
                  <span className="fs1" aria-hidden="true" data-icon="&#xe011;"></span>
                </div>
                <div className="desc">
                  <h3 className="sub-title">Review &amp; Refactoring</h3>
                  <p>We review your source code and propose a detailed refactoring plan to improve the performance, stability and maintainability of your software product.</p>
                </div>
              </div>
            </div>

            <div className="item col-xl-4 col-md-6 col-12">
              <div className="item-inner">
                <div className="header-box">
                  <span className="fs1" aria-hidden="true" data-icon="&#xe007;"></span>
                </div>
                <div className="desc">
                  <h3 className="sub-title">Training &amp; Courses</h3>
                  <p>We offer a set of courses to help your team gain from basic to advanced knowledge of object oriented programming and write clean & quality code.</p>
                </div>
              </div>
            </div>

          </div>

          <a className="btn btn-cta btn-cta-primary" href={contactUrl}>Get a quote</a>

        </div>

      </section>
    );
  }

export default Services;
