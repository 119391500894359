import React, { Component } from 'react';

import Intl from '../lang/intl';
import RouteNames from '../routes/names';

import { subscribeNewsletter } from '../api';

import { phoneNumber, emailAddress, emailRegex } from '../constant';

class Footer extends Component {

    state = {
        email: '',
        errorMessage: '',
        successMessage: ''
    }

    onEmailChanged = (event) => {
        event.preventDefault();
        this.setState({ email: event.target.value })
    }

    canSubscribe = () => {
        return emailRegex.test(String(this.state.email).toLowerCase());
    }

    subscribe = () => {

        this.setState({ 
            errorMessage: '',
            successMessage: ''
        });

        subscribeNewsletter(this.state.email, success => {
            if (success) {
                this.setState({ 
                    email: '', 
                    successMessage: 'You are now registered to receive our newsletter.'
                })
            }
            else {
                this.setState({ 
                    errorMessage: 'We could not process your request at this time. Please try again later.'
                })
            }
        });
    }

    render() {

        const aboutUrl = RouteNames.About.replace(':lang', this.props.lang);
        const expertiseUrl = RouteNames.Expertise.replace(':lang', this.props.lang);
        const careerUrl = RouteNames.Career.replace(':lang', this.props.lang);
        const contactUrl = RouteNames.Contact.replace(':lang', this.props.lang);

        return (
            <footer className="footer">
                <div className="footer-content">
                    <div className="container">
                        <div className="row">

                            <div className="footer-col col-lg-3 col-md-4 col-12 links-col">
                                <div className="footer-col-inner">
                                    <h3 className="sub-title">Quick Links</h3>
                                    <ul className="list-unstyled">
                                    {/* <li><a href={aboutUrl}><Intl id="header.about" /></a></li> */}
                                    <li><a href={expertiseUrl}><Intl id="header.expertise" /></a></li>
                                    <li><a href={careerUrl}><Intl id="header.career" /></a></li>
                                    <li><a href={contactUrl}><Intl id="header.contact" /></a></li>
                                    </ul>
                                </div>
                            </div>

                            <hr className="d-block d-md-none" />

                            <div className="footer-col col-lg-4 col-12 contact-col">
                                <div className="footer-col-inner">
                                    <h3 className="sub-title">Get In Touch</h3>
                                    <p className="intro"></p>
                                    <div className="row">
                                        <p className="adr clearfix col-lg-12 col-md-4">
                                            <span className="fs1" aria-hidden="true" data-icon="&#xe01d;"></span>
                                            <span className="adr-group">
                                                <span className="street-address">208-935 Boul. Décarie</span><br />
                                                <span className="city">Saint-Laurent (QC) H4L 3M3</span><br />
                                                <span className="country-name">CANADA</span>
                                            </span>
                                        </p>
                                        <p className="tel col-lg-12 col-md-4 col-12">
                                            <span className="fs1" aria-hidden="true" data-icon="&#x77;"></span>
                                            <span className=""><a href={"tel:" + phoneNumber}>{phoneNumber}</a></span><br />
                                            <span className="fs1" aria-hidden="true" data-icon="&#xe010;"></span>
                                            <span className=""><a href={"mailto:" + emailAddress}>{emailAddress}</a></span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <hr className="d-block d-md-none" />

                            <div className="footer-col col-lg-5 col-12 contact-col">
                                <div className="footer-col-inner">
                                    <h3 className="sub-title">Subscribe to our newsletters</h3>
                                    {this.state.errorMessage.length > 0 && <div id="error">
                                        <div className="alert alert-danger">
                                            <button type="button" className="close" onClick={() => this.setState({ errorMessage: '' })}>×</button>
                                            <strong>{this.state.errorMessage}</strong>
                                        </div>
                                    </div>}
                                    {this.state.successMessage.length > 0 && <div id="error">
                                        <div className="alert alert-success">
                                            <button type="button" className="close" onClick={() => this.setState({ successMessage: '' })}>×</button>
                                            <strong>{this.state.successMessage}</strong>
                                        </div>
                                    </div>}
                                    <div className="col-lg-12 col-12 form-group pl-0">
                                        <span>Get our email newsletter</span>
                                    </div>
                                    <div className="col-lg-12 col-12 form-group pl-0">
                                        <input  type="email" 
                                                className="form-control" 
                                                id="newsletter-email" 
                                                value={this.state.email}
                                                placeholder="Enter your email address"
                                                onChange={this.onEmailChanged} />
                                    </div>
                                    <div className="col-lg-5 col-5 form-group pl-0">
                                        <button type="submit" 
                                                id="newsletter-subscribe" 
                                                className="btn btn-block btn-cta btn-cta-primary" 
                                                disabled={!this.canSubscribe()}
                                                onClick={this.subscribe}>
                                            Subscribe
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

                <div className="bottom-bar">
                    <div className="container center">
                    <ul className="social-icons list-inline">
                        <li className="list-inline-item"><a href="https://twitter.com/tweetkenz" target="_blank" rel="noopener noreferrer nofollow"><i className="fa fa-twitter"></i></a></li>
                        <li className="list-inline-item"><a href="http://www.linkedin.com/company/kenz-software" target="_blank" rel="noopener noreferrer nofollow"><i className="fa fa-linkedin"></i></a></li>
                    </ul>
                    <small className="copyright text-center">Kenz Software &copy; 2010 - {new Date().getFullYear()}</small>
                    </div>
                </div>

            </footer>
        );
    }
}

export default Footer;