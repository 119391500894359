import React from 'react';

import { amazonImageUrl } from '../../constant';

const Cloud = (props) => {
    return (
      <div className="expertise">

        <p className="intro title text-center">Cloud Migration</p>

        <ul className="expertise-list list-inline row">
          <li className="col col-xs-6 col-md-3"><img className="img-fluid" src={amazonImageUrl + "/azure.png"} alt=".NET" /></li>
          <li className="col col-xs-6 col-md-3"><img className="img-fluid" src={amazonImageUrl + "/googlecloud.png"} alt="ASP.NET" /></li>
          <li className="col col-xs-6 col-md-3"><img className="img-fluid" src={amazonImageUrl + "/amazon.png"} alt="SQL Server" /></li>
          {/* <li className="col-md-3 col-6"><img className="img-fluid" src="/images/expertise/digitalocean.png" alt="Xamarin" /></li> */}
        </ul>

      </div>
    );
  }

export default Cloud;
