import React, { useState } from 'react';

import RouteNames from '../../routes/names';

import Animation from '../../animations';
import Carousel from 'react-bootstrap/Carousel'

import { amazonImageUrl } from '../../constant';

const getContent = (lang) => {
  const contactUrl = RouteNames.Contact.replace(':lang', lang);
  return (<section id="home-promo" className="home-promo section">
            <div className="container text-center">
              <h2 className="title mt-4">
                <span className="upper">We design and develop</span>
                <span className="middle">great solutions</span>
                <span className="bottom">for software companies</span>
              </h2>
              <a className="btn btn-cta btn-cta-primary" href={contactUrl}>Contact Us</a>
            </div>
          </section>);
}

const getItems = (lang) => {

  const content = getContent(lang);
  
  return [
            {
              key: 'slide1',
              src: amazonImageUrl + "/background/promo-background-1.jpg",
              altText: 'Kenz Software',
              content: content
            },
            {
              key: 'slide2',
              src: amazonImageUrl + "/background/promo-background-2.jpg",
              altText: 'Kenz Software',
              content: content
            },
            {
              key: 'slide3',
              src: amazonImageUrl + "/background/promo-background-3.jpg",
              altText: 'Kenz Software',
              content: content
            }
          ];
 }

const Slides = (props) => {
    
    const [index, setIndex] = useState(0);
    const [direction, setDirection] = useState(null);
  
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
      setDirection(e.direction);
    };

    const items = getItems(props.lang);
  
    const slides = items.map((item) => {
        return (
          <Carousel.Item key={item.key} className="bg-slider">
            <img src={item.src} className="img-slider" alt={item.altText} />
            <Carousel.Caption>
              <Animation name="fadeIn">{item.content}</Animation>
            </Carousel.Caption>
          </Carousel.Item>
        );
    });

    return (<div>
              <Carousel activeIndex={index} direction={direction} onSelect={handleSelect}>
                {slides}
              </Carousel>
            </div>
    );
  }

export default Slides;
