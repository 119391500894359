import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

import Animation from '../../animations';

import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google'

import { sendMessage } from '../../api';
import { phoneNumber, emailAddress, companyName } from '../../constant';

// eslint-disable-next-line
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

class Contact extends Component  {

    state = {
        inputName: '',
        inputEmail: '',
        inputSubject: '',
        inputMessage: '',
        captchaToken: '',
        errorMessage: '',
        successMessage: '',
        submitting: false
    }
    
    constructor(props, context) {
        super(props, context);
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    componentDidMount() {
        
        loadReCaptcha();

        // if (this.captcha) {
        //     this.captcha.reset();
        // }
    }

    canSubmit = () => {
        return !this.state.submitting && this.isFormValid();
    }

    isFormValid = () => {
        return this.state.inputEmail.length > 0 && this.state.inputName.length > 0 && 
            this.state.inputSubject.length > 0 && this.state.inputMessage.length > 0 && 
            emailRegex.test(String(this.state.inputEmail).toLowerCase()) && this.state.captchaToken.length > 0;
    }

    // Handle visitor's interaction with inputs
    handleInput = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    
    handleCheckbox = event => {
        this.setState({
          [event.target.name]: event.target.checked
        })
    }
    
    onLoadRecaptcha() {
        if (this.captcha) {
            this.captcha.reset();
        }
    }

    verifyCallback(recaptchaToken) {
      this.setState({
        captchaToken: recaptchaToken
      })
    }

    handleFormSubmit = event => {

        event.preventDefault()
        
        if (this.isFormValid()) {
            
            this.setState({
                errorMessage: '',
                successMessage: '',
                submitting: true
            });
    
            const request = {
                name: this.state.inputName,
                email: this.state.inputEmail,
                subject: this.state.inputSubject,
                message: this.state.inputMessage,
                captchaToken: this.state.captchaToken
            };

            sendMessage(request, status => { 
                if (status) {
                    this.setState({
                        inputEmail: '',
                        inputSubject: '',
                        inputMessage: '',
                        inputName: '',
                        captchaToken: '',
                        errorMessage: '',
                        successMessage: 'Thank you! We have received your message. We will contact you shortly.',
                        submitting: false
                    })
                }
                else {
                    this.setState({
                        submitting: false,
                        captchaToken: '',
                        errorMessage: 'We could not send your message at this time. Please try again later.'
                    })
                }

                if (this.captcha) {
                    this.captcha.reset();
                }
            });
        }
    }
      
    render() {
        return (
            <div className="contact-page">
                <MetaTags>
                    <title>{companyName + " | Contact Us"}</title>
                    <meta name="description" content="Get in touch if you want to find out more about our services. We'd love to hear from you!" />
                </MetaTags>
                <section className="promo promo-contact section">
                    <Animation name="fadeIn">
                    <div className="container text-center">
                        <h2 className="title">Contact Us</h2>
                        <p className="intro">Get in touch if you want to find out more about our services. We'd love to hear from you!</p>
                    </div>
                    </Animation>
                </section>
                <section className="contact-section section">

                    <div className="container">

                        <h2 className="title text-center">Start your project today</h2>

                        <p className="intro text-center">
                            Want to turn a great idea into an outstanding product?
                            Have an existing project that needs enhancement?
                            Contact us today <i className="fa fa-smile-o"></i>
                        </p>

                        <ul className="contact-info list-inline text-center">
                            <li className="tel list-inline-item"><span className="fs1" aria-hidden="true" data-icon="&#x77;"></span><br /> <a href={phoneNumber}>{phoneNumber}</a></li>
                            <li className="email list-inline-item"><span className="fs1" aria-hidden="true" data-icon="&#xe010;"></span><br /> <a href={"mailto:" + emailAddress}>{emailAddress}</a></li>
                        </ul>
                    
                        <div className="row">
                            <div className="col col-xs-12 col-sm-12 col-md-10 col-lg-8 mx-auto">
                                {this.state.errorMessage.length > 0 && <div id="error">
                                    <div className="alert alert-danger">
                                        <button type="button" className="close" onClick={() => this.setState({ errorMessage: '' })}>×</button>
                                        <strong>{this.state.errorMessage}</strong>
                                    </div>
                                </div>}
                                {this.state.successMessage.length > 0 && <div id="error">
                                    <div className="alert alert-success">
                                        <button type="button" className="close" onClick={() => this.setState({ successMessage: '' })}>×</button>
                                        <strong>{this.state.successMessage}</strong>
                                    </div>
                                </div>}
                                <div className="contact-form">
                                    <form name="sentMessage" id="contactForm" method="post" noValidate>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="row control-group">
                                                    <div className="form-group col-12 controls">
                                                        <input type="text" name="inputName" value={this.state.inputName} onChange={this.handleInput} className="form-control" placeholder="FULLNAME" id="name" required />
                                                        <p className="help-block">
                                                            {this.state.errorMessage.length > 0 && this.state.inputName.length === 0 && "Please, enter your fullname."}
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-md-6">
                                                <div className="row control-group">
                                                    <div className="form-group col-12 controls">
                                                        <input type="email" name="inputEmail" value={this.state.inputEmail} onChange={this.handleInput} className="form-control" placeholder="EMAIL ADDRESS" id="email" required />
                                                        <p className="help-block">
                                                            {this.state.errorMessage.length > 0 && this.state.inputEmail.length === 0 && "Please, enter your email address."}
                                                            {this.state.errorMessage.length > 0 && this.state.inputEmail.length > 0 && !emailRegex.test(String(this.state.inputEmail).toLowerCase()) && "SVP, entrer une adresse Email valide."}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row control-group">
                                            <div className="form-group col-12  controls">
                                                <input type="text" name="inputSubject" value={this.state.inputSubject} onChange={this.handleInput} className="form-control" placeholder="SUBJECT" id="subject" required />
                                                <p className="help-block">
                                                    {this.state.errorMessage.length > 0 && this.state.inputSubject.length === 0 && "Please, enter your message subject."}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row control-group">
                                            <div className="form-group col-12 controls">
                                                <textarea rows="5" name="inputMessage" value={this.state.inputMessage} onChange={this.handleInput} className="form-control" placeholder="ENTER MESSAGE HERE..." id="message" required></textarea>
                                                <p className="help-block">
                                                    {this.state.errorMessage.length > 0 && this.state.inputMessage.length === 0 && "Please, enter your message."}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row control-group">
                                            <div className="form-group col-12 controls">
                                                <ReCaptcha
                                                    ref={(el) => {this.captcha = el;}}
                                                    size="normal"
                                                    render="explicit"
                                                    data-theme="dark" 
                                                    sitekey="6Ld-zMEUAAAAAJrfrWkKOM5PACbkq3quctoC_hxB"
                                                    onloadCallback={this.onLoadRecaptcha}
                                                    verifyCallback={this.verifyCallback}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-12">
                                                <button type="submit" 
                                                        onClick={this.handleFormSubmit} 
                                                        disabled={!this.canSubmit()} 
                                                        className={"btn btn-block btn-cta btn-cta-primary"}>
                                                    SEND MESSAGE
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                {/* <section className="map-section section">
                    <div className="gmap-wrapper">
                        <iframe title="gMap" width="100%" height="600" id="gmap_canvas" src="https://maps.google.com/maps?q=2300 sherbrooke est&t=&z=15&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                    </div>
                </section> */}
            </div>
        );
    }
}

export default Contact;