import { addMessage, subscribeEmail } from './firebase';

export const sendMessage = async (request, callback) => {

    addMessage(request).then((response) => {
        
        callback(response.data.status);

    }).catch((error) => {
        callback(false);
    });

}

export const subscribeNewsletter = async (email, callback) => {
    
    const request = { email: email };

    subscribeEmail(request).then((response) => {
        
        callback(response.data.status);

    }).catch((error) => {
        callback(false);
    });

}