import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import ReactDomServer from 'react-dom/server';

import { websiteAddress } from '../constant';

const UrlSet = (props) => React.createElement("urlset", props);
const Url = (props) => React.createElement("url", props);
const Location = (props) => React.createElement("loc", props);
const LastModif = (props) => React.createElement("lastmod", props);
const Frequency = (props) => React.createElement("changefreq", props);
const Priority = (props) => React.createElement("priority", props);


class RouteSwitch extends Component {

    generateSitemap = async () => {
        
        let key = 0;
        const today = new Date();

        const sitemap = ReactDomServer.renderToStaticMarkup(
            <UrlSet xmlns='http://www.sitemaps.org/schemas/sitemap/0.9'>
                {this.props.children.filter(c => c.props.path !== '*').map(c => 
                    (<Url key={key++}>
                        <Location>{websiteAddress + c.props.path.replace(':lang', 'en')}</Location>
                        <LastModif>{today.getFullYear()}-{today.getMonth()}-{today.getDate()}</LastModif>
                        <Frequency>monthly</Frequency>
                        <Priority>0.5</Priority>
                    </Url>)
                )}
            </UrlSet>)

        console.log(sitemap);
    }

    render() {

        this.generateSitemap();

        return (<Switch>
                    {this.props.children}
                </Switch>);
    }
}

export default RouteSwitch;