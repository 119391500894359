import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

import { companyName } from '../../constant';

class NotFound extends Component {
    
    render() {

        const lang = this.props.match.params.lang;

        return (
            <div className="404-page">
                <MetaTags>
                    <title>{companyName + " | Page Not Found"}</title>
                    <meta name="description" content="The page you were looking for doesn't exist or has been moved." />
                </MetaTags>
                <section className="section-404 section">
                    <div className="container text-center">
                        <h2 className="title-404 text-center">404</h2>
                        <p className="intro text-center">The page you were looking for doesn't exist or has been moved.</p>
                        <div className="center-block">
                            <a className="btn btn-cta btn-cta-secondary" href={"/" + lang}>Back to Home</a>
                        </div>
                    </div>
                </section>                    
            </div>
        );
    }
}

export default NotFound;