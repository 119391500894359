import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

import Animation from '../../animations';

import RouteNames from '../names';

import { emailAddress, companyName } from '../../constant';

class Career extends Component {
    
    render() {
        const lang = this.props.match.params.lang;
        const contactUrl = RouteNames.Contact.replace(':lang', lang);

        return (
            <div className="career-page">
                <MetaTags>
                    <title>{companyName + " | Career"}</title>
                    <meta name="description" content="Are you ready for a new challenges?" />
                </MetaTags>
                <section className="promo promo-career section">
                    <Animation name="fadeIn">
                    <div className="container text-center">
                        <h2 className="title">Career</h2>
                        <p className="intro">Are you ready for a new challenges?</p>
                    </div>
                    </Animation>
                </section>

                <section id="team" className="team section">

                    <div className="container">

                        <h2 className="title text-center">Our philosophy</h2>
                        <p className="intro text-center">
                            We share a passion for software development that makes us successful.
                        </p>

                        <div className="row">
                            <div className="info col-lg-7 col-md-6 col-12 text-justify">

                                <p>
                                    We offer our staff a unique possibility to work on challenging projects from all over the world. We help prepare people for new opportunities and challenges in their careers.
                                    With positions at every level and expertise in a range of markets, we create an environment where you can learn, grow, and thrive.
                                </p>

                                <p>
                                    <b>Join us as an independent consultant</b> —
                                    As a consultant, your job will involve finding innovative solutions to challenging problems.
                                    Leveraging your technical skills, you will design and improve our clients’ products.
                                </p>

                                <p>We are constantly looking for Mobile iPhone/Android, .NET C#, ASP.NET MVC and Ruby On Rails developers. Please submit your resume at <a href={"mailto:" + emailAddress}>{emailAddress}</a></p>

                                <p>If you share our commitment to doing what's best for our clients, enjoy working in dynamic teams and share our passion for programming, you'll fit right in <i className="fa fa-smile-o"></i>.</p>

                            </div>

                            <div className="partner col-lg-4 col-md-5 col-12 ml-lg-auto mr-lg-auto text-justify">

                                <h5 className="sub-title mb-4">Want to partner with us?</h5>

                                <p>In today’s competitive economy, cultivating strategic partner relationships is crucial to any company’s long-term success.</p>

                                <p>A close partnership is the only way to meet the high demands of innovation in today’s technology market — which dictate that companies must deliver a fast time to market with high quality software.</p>

                                <a href={contactUrl} className="btn btn-cta btn-cta-primary">Contact us today!</a>

                            </div>

                        </div>

                        <br /><br />

                        <h2 className="title text-center">Join our team</h2>

                        <p className="intro text-center">
                            Interested in a career with us? Submit your resume at <a href={"mailto:" + emailAddress}>{emailAddress}</a>.
                        </p>

                    </div>

                </section>

            </div>
        );
    }
}

export default Career;