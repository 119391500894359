import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

import About from './_about';
import Clients from './_clients';
import Slides from './_slides';

import { companyName } from '../../constant';
import { getCurrentLang } from '../../lang/constants';

import structuredData from '../../structuredData';

class Home extends Component {

    render() {

        const lang = getCurrentLang(true);
        
        return (
            <div className="home-page">
                <MetaTags>
                    <title>{companyName + " | Home"}</title>
                    <meta name="description" content="We are a team of IT professionals based in Montreal. Over the years, we have helped software companies build great solutions." />
                    <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
                </MetaTags>
                <Slides lang={lang} />  
                <About lang={lang} />    
                <Clients lang={lang} />
                                  
            </div>
        );
    }
}

export default Home;