import React from 'react';

import { amazonImageUrl } from '../../constant';

const Frontend = (props) => {
    return (
      <div className="expertise">

        <p className="intro title text-center">Front-End Development</p>

        <ul className="expertise-list list-inline row">
          <li className="col col-xs-6 col-md-2 mb-4"><img className="img-fluid" src={amazonImageUrl + "/html5.png"} alt="HTML5" /></li>
          <li className="col col-xs-6 col-md-2 mb-4"><img className="img-fluid" src={amazonImageUrl + "/javascript.png"} alt="Javascript" /></li>
          <li className="col col-xs-6 col-md-2 mb-4"><img className="img-fluid" src={amazonImageUrl + "/css3.png"} alt="CSS3" /></li>
          <li className="col col-xs-6 col-md-2 mb-4"><img className="img-fluid" src={amazonImageUrl + "/angular.png"} alt="Angular" /></li>
          <li className="col col-xs-6 col-md-2 mb-4"><img className="img-fluid" src={amazonImageUrl + "/react.png"} alt="React" /></li>
          <li className="col col-xs-6 col-md-2 mb-4"><img className="img-fluid" src={amazonImageUrl + "/bootstrap.png"} alt="Bootstrap" /></li>
        </ul>

      </div>
    );
  }

export default Frontend;
