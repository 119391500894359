export const defaultLang = 'en';

const isLangValid = (lang) => {
    // return (lang === 'en' || lang === 'fr');
    return lang === 'en';
}

export const getCurrentLang = (setDefault) => {
    const params = window.location.pathname.split('/');
    if (params.length > 1 && isLangValid(params[1])) {
        return params[1];
    }
    return setDefault ? defaultLang :  null;
}