module.exports = {

	// Header
	"header.home":"Home",
	"header.about":"About",
	"header.expertise":"Expertise",
	"header.career":"Career",
	"header.contact":"Contact",

	// Footer
	"footer.aboutUs": "About",
	"footer.pricing": "Pricing",
	"footer.terms": "Terms & Conditions",
	"footer.feedback": "Feedback",
	"footer.report": "Report",
	"footer.support": "Support",
	"footer.faq(s)": "FAQ",
	
}
