module.exports = {

	// Header
	"header.home":"Accueil",
	"header.about":"À propos",
	"header.expertise":"Expertise",
	"header.career":"Carrière",
	"header.contact":"Contact",

	// Footer
	"footer.aboutUs": "A propos de nous",
	"footer.pricing": "Tableau de prix",
	"footer.terms": "Termes & Conditions",
	"footer.feedback": "Feedback",
	"footer.report": "Report",
	"footer.support": "Support",
	"footer.faq(s)": "Aide",

}
