import React, { Component } from 'react';

import Header from './_header';
import Footer from './_footer';

class Layout extends Component {

    topControlStyle = {
        position: 'fixed', 
        bottom: 5,
        right: 5, 
        opacity: 1,
        cursor: 'pointer'
    }

    state = {
        shrink: false,
        showBackToTop: false
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (event) => {
        event.preventDefault();   
        this.setState({ 
            shrink: window.scrollY > 80, 
            showBackToTop: window.scrollY > 200 
        });
    }

    scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    render() {

        const currentStyle = { 
            ...this.topControlStyle, 
            opacity: this.state.showBackToTop ? 1 : 0 
        };

        return (<div>
                    <Header lang={this.props.lang} shrink={this.state.shrink} />
                    <div style={{minHeight: 400}}>
                        {this.props.children}
                    </div>
                    <Footer lang={this.props.lang} />
                    <div id="topcontrol" title="Scroll Back to Top" style={currentStyle} onClick={this.scrollToTop}>
                        <i className="fa fa-angle-up"></i>
                    </div>
                </div>);
    }
}

export default Layout;
