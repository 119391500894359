import React, { Component } from 'react';

import Definition from './definition';
import { IntlProvider } from 'react-intl';

class LanguageProvider extends Component {
    
    render() {
                
        const languageDef = Definition[this.props.lang];

        return (
            <IntlProvider locale={languageDef.locale} messages={languageDef.messages}>
                {this.props.children}	
            </IntlProvider>  
        );
      }
}

export default LanguageProvider;
