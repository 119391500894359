import React from 'react';

import { amazonImageUrl } from '../../constant';

const Clients = (props) => {

    return (
      <section id="logos" className="logos section">

        <div className="container text-center">

          <h2 className="title">Who we have worked with</h2>

          <p>Over the years, we have collaborated with the following companies.</p>

          <ul className="logo-list list-inline row last">
            <li className="col-md-3 col-6"><img className="img-fluid" src={amazonImageUrl + "/client-2.png"} alt="Telus Health Solutions" /></li>
            <li className="col-md-3 col-6"><img className="img-fluid" src={amazonImageUrl + "/client-3.png"} alt="Green Vision Media" /></li>
            <li className="col-md-3 col-6"><img className="img-fluid" src={amazonImageUrl + "/client-1.png"} alt="Orange - IT &amp; Labs Canada" /></li>
            <li className="col-md-3 col-6"><img className="img-fluid" src={amazonImageUrl + "/client-5.png"} alt="Meritek Conseil" /></li>
          </ul>

          <br />

        </div>

      </section>
    );
  }

export default Clients;
