import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import Intl from '../lang/intl';
import RouteNames from '../routes/names';

class Header extends Component {

    render() {
                
        const homeUrl = RouteNames.Home.replace(':lang', this.props.lang);
        const aboutUrl = RouteNames.About.replace(':lang', this.props.lang);
        const expertiseUrl = RouteNames.Expertise.replace(':lang', this.props.lang);
        const careerUrl = RouteNames.Career.replace(':lang', this.props.lang);
        const contactUrl = RouteNames.Contact.replace(':lang', this.props.lang);

        const shrinkClassName = this.props.shrink ? "header-shrink" : "";

        return (
            <header id="header" className={"header fixed-top " + shrinkClassName}>

                <div className="container">

                    <h1 className="logo">
                        <a href={homeUrl}><span className="highlight">Kenz</span> Software</a>
                    </h1>

                    <nav className="main-nav navbar navbar-expand-md navbar-dark" role="navigation">

                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse" aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div id="navbar-collapse" className="navbar-collapse collapse justify-content-end">
                            <ul className="nav navbar-nav">
                                <li className="nav-item">
                                    <NavLink to={homeUrl} exact className="nav-link" activeClassName="active"><Intl id="header.home" /></NavLink>
                                </li>
                                {/* <li className="nav-item">
                                    <NavLink to={aboutUrl} className="nav-link" activeClassName="active"><Intl id="header.about" /></NavLink>
                                </li> */}
                                <li className="nav-item">
                                    <NavLink to={expertiseUrl} className="nav-link" activeClassName="active"><Intl id="header.expertise" /></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={careerUrl} className="nav-link" activeClassName="active"><Intl id="header.career" /></NavLink>
                                </li>
                                <li className="nav-item last">
                                    <NavLink to={contactUrl} className="nav-link" activeClassName="active"><Intl id="header.contact" /></NavLink>
                                </li>
                            </ul>
                        </div>
                    </nav>

                </div>

            </header>
        );
    }
}

export default Header;