import React, { Component } from 'react';

import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';

import LanguageProvider from './lang';
import { getCurrentLang } from './lang/constants';

import Layout from './layout';
import RouteNames from './routes/names';
import RouteSwitch from './routes/switch';

import Home from './routes/home';
import About from './routes/about';
import Expertise from './routes/expertise';
import Career from './routes/career';
import Contact from './routes/contact';
import NotFound from './routes/notfound';

import './style';

// import AsyncComponent from './AsyncComponent';

// const AsyncHomeComponent = AsyncComponent(() => {
//   return import('./routes/home');
// });

// const AsyncAboutComponent = AsyncComponent(() => {
//   return import('./routes/about');
// });

// const AsyncExpertiseComponent = AsyncComponent(() => {
//   return import('./routes/expertise');
// });

// const AsyncCareerComponent = AsyncComponent(() => {
//   return import('./routes/career');
// });

// const AsyncContactComponent = AsyncComponent(() => {
//   return import('./routes/contact');
// });

// const Async404Component = AsyncComponent(() => {
//   return import('./routes/notfound');
// });

class App extends Component {

  render() {
    const currentLang = getCurrentLang(true);

    return (
      <div className="App">
        <Router>         
          <LanguageProvider lang={currentLang}>
            <Layout lang={currentLang}>
              <RouteSwitch>         		
                <Route exact path={RouteNames.About} component={About} />
                <Route exact path={RouteNames.Expertise} component={Expertise} />
                <Route exact path={RouteNames.Career} component={Career} />
                <Route exact path={RouteNames.Contact} component={Contact} />
                <Route exact path="/en" component={Home} />
                {/* <Route exact path="/fr" component={Home} /> */}
                <Route exact path="/" render={() => <Redirect to="/en" />} />
                <Route path="*" component={NotFound} />
              </RouteSwitch>
            </Layout>
          </LanguageProvider>
        </Router>
    </div>
    );

  }
}

export default App;
