/**
 * App Language Provider
 * Add more locales here
 */
import enEntry from './entries/en';
import frLEntry from './entries/fr';

const Language = {
    en: enEntry,
    fr: frLEntry,
};

export default Language;
