import React from 'react';

import { amazonImageUrl } from '../../constant';

const Backend = (props) => {
    return (
      <div className="expertise">

        <p className="intro title text-center">Back-End Development</p>

        <ul className="expertise-list list-inline row">
          <li className="col col-xs-6 col-md-3"><img className="img-fluid" src={amazonImageUrl + "/dotnet.png"} alt="Miscrosoft .NET" /></li>
          <li className="col col-xs-6 col-md-3"><img className="img-fluid" src={amazonImageUrl + "/aspnet.png"} alt="ASP.NET MVC" /></li>
          <li className="col col-xs-6 col-md-3"><img className="img-responsive" src={amazonImageUrl + "/aspnet-core.png"} alt="ASP.NET Core" /></li>
          <li className="col col-xs-6 col-md-3"><img className="img-fluid" src={amazonImageUrl + "/ruby-on-rails.png"} alt="Ruby on rails" /></li>
        </ul>

      </div>
    );
  }

export default Backend;
