import React from 'react';
import { fadeIn, fadeInDown } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
 
const styles = {
  fadeIn: {
    animation: 'x 1s',
    animationName: Radium.keyframes(fadeIn, 'fadeIn')
  },
  fadeInDown: {
    animation: 'x 1s',
    animationName: Radium.keyframes(fadeInDown, 'fadeInDown')
  }
}
 
class FadeInDown extends React.Component {
  render() {
    const { name, children } = this.props;

    return (<StyleRoot>
                <div style={styles[name]}>
                    {children}
                </div>
            </StyleRoot>);
  }
}

export default FadeInDown