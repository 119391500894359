import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

import Animation from '../../animations';

import Cloud from './_cloud';
import Backend from './_backend';
import Frontend from './_frontend';

import { companyName } from '../../constant';

class Services extends Component {
    
    render() {

        // const lang = this.props.match.params.lang;

        return (
            <div className="expertise-page">
                <MetaTags>
                    <title>{companyName + " | Expertise"}</title>
                    <meta name="description" content="We make best use of cutting-edge technology to enhance your software products." />
                </MetaTags>
                <section className="promo promo-expertise section">
                    <Animation name="fadeIn">
                        <div className="container text-center">
                            <h2 className="title">Our expertise</h2>
                            <p className="intro">We make best use of cutting-edge technology to enhance your software products.</p>
                        </div>
                    </Animation>
                </section>
                <section id="work-list" className="work-list section">
                    <div className="container">

                        <h2 className="title text-center">Our Area Of Expertise</h2>

                        <Frontend />
                        <Backend />
                        <Cloud />

                    </div>
                </section>
            </div>
        );
    }
}

export default Services;