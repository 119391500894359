import enMessages from '../locales/en';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';

const EnLang = {
    messages: {
        ...enMessages
    },
    locale: 'en'
};
export default EnLang;