import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

import Team from './_team';
import Services from './_services';
import Animation from '../../animations';

import { companyName } from '../../constant';

class About extends Component {
    
    render() {

        const lang = this.props.match.params.lang;

        return (
            <div className="about-page">
                <MetaTags>
                    <title>{companyName + " | About Us"}</title>
                    <meta name="description" content="We share a passion for software development that makes our company successful." />
                </MetaTags>
                <section className="promo promo-about section">
                    <Animation name="fadeIn">
                        <div className="container text-center">
                            <h2 className="title">About Us</h2>
                            <p className="intro">We share a passion for software development that makes our company successful.</p>
                        </div>
                    </Animation>
                </section>
                {/* <Team lang={lang} />   */}
                <Services lang={lang} />    
            </div>
        );
    }
}

export default About;